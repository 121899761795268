import { AuthService, OrderService, AgentOrderService, SettingsService, ShopOrderService, ShopSettingsService, TicketService } from "../services";
import ShoppingCartService from "@/services/shop/ShoppingCartService";

let actions = {
   login(context, user) {
      return AuthService.login(user)
         .then((data) => {
            context.commit("login_success", data);
            return Promise.resolve(data);
         })
         .catch((err) => {
            context.commit("login_failure");
            return Promise.reject(err);
         });
   },

   adminAutoLogin(context, user) {
      return AuthService.adminAutoLogin(user)
         .then((data) => {
            context.commit("login_success", data);
            return Promise.resolve(data);
         })
         .catch((err) => {
            context.commit("login_failure");
            return Promise.reject(err);
         });
   },

   logout(context) {
      AuthService.logout().then((res) => {
         context.commit("logout");
      });
   },

   register(context, user) {
      return AuthService.register(user)
         .then((data) => {
            context.commit("login_success", data);
            return Promise.resolve(data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   refreshUserData(context) {
      return AuthService.refreshUserData()
         .then((user) => {
            context.commit("update_user_data", user);
            return Promise.resolve(user);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   refreshSiteSettings(context) {
      return SettingsService.refreshSettings()
         .then((data) => {
            context.commit("update_site_settings", data.settings);
            return Promise.resolve(data.settings);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   refreshShopSettings(context) {
      return ShopSettingsService.get().then((data) => {
         if (data && data.status === "success") {
            context.commit("update_shop_settings", data.shop_settings);
            return Promise.resolve(data.shop_settings);
         }

         // else {
         //   return Promise.reject(data)
         // }
      });

      // .catch((err) => {
      //   return Promise.reject(err);
      // })
   },

   refreshShoppingCart(context) {
      return ShoppingCartService.get()
         .then((data) => {
            if (data && data.status === "success") {
               context.commit("update_shopping_cart", data.shopping_cart);
               return Promise.resolve(data.shopping_cart);
            }

            // else {
            //   return Promise.reject(data)
            // }
         })
         .catch((_r) => {});
   },

   refreshPendingShopOrders(context) {
      ShopOrderService.get_all({
         status: "",
         sort_by: "created_at",
         sort_order: 1,
         page: 1,
         size: -1,
      })
         .then((resp) => {
            if (resp && resp.status === "success") {
               let countIncompleteOrders = 0;
               for (let order of resp.orders) {
                  if (order.current_status !== "cancelled" && order.current_status !== "completed") countIncompleteOrders++;
                  else continue;
               }
               context.commit("update_pending_shop_orders", countIncompleteOrders);
            }
         })
         .catch((_e) => {});
   },

   refreshMyIncompleteShopOrders(context) {
      ShopOrderService.get_mine({
         status: "",
         sort_by: "created_at",
         sort_order: 1,
         page: 1,
         size: -1,
      })
         .then((data) => {
            if (data && data.status === "success") {
               let countIncompleteOrders = 0;
               for (let order of data.orders) {
                  if (order.current_status !== "cancelled" && order.current_status !== "completed") countIncompleteOrders++;
                  else continue;
               }
               context.commit("update_my_incomplete_shop_orders", countIncompleteOrders);
            }
         })
         .catch((_e) => {});
   },

   refreshThemeSettings(context) {
      return SettingsService.getThemeSettings()
         .then((data) => {
            context.commit("update_theme_settings", data);
            return Promise.resolve(data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   refreshToken(context, access_token) {
      context.commit("refresh_token", access_token);
   },

   getPendingOrders(context) {
      OrderService.getPendingOrdersCount()
         .then((data) => {
            context.commit("pending_orders", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getUserPendingOrders(context) {
      OrderService.getUserPendingOrdersCount()
         .then((data) => {
            context.commit("user_pending_orders", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getWarningOrders(context) {
      OrderService.getWarningOrdersCount()
         .then((data) => {
            context.commit("warning_orders", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getAgentPendingOrders(context) {
      AgentOrderService.getPendingOrdersCount()
         .then((data) => {
            context.commit("agent_pending_orders", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getAgentWarningOrders(context) {
      AgentOrderService.getWarningOrdersCount()
         .then((data) => {
            context.commit("agent_warning_orders", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getPendingMessages(context) {
      context.commit("pending_message", 0);
   },

   getOpenTickets(context) {
      TicketService.getOpenTicketsCount()
         .then((data) => {
            context.commit("open_tickets", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },

   getUserOpenTickets(context) {
      TicketService.getUserOpenTicketsCount()
         .then((data) => {
            context.commit("open_tickets", data.count);
            return Promise.resolve(data.count);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   },
};

export default actions;
