import http from '../httpConfig'

export class ApiService {
  
  static getApis(params) {
    return http
      .get("/access_api", { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static getUserApis(params) {
    return http
      .get(`/access_api_user`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static buyApiAccess(api) {
    return http
      .post("/access_api", api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static createApiAccess(api) {
    return http
      .post(`/access_api_create`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static updateApiAccess(api) {
    return http
      .put(`/access_api/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static updateApiAccessUser(api) {
    return http
      .put(`/access_api_user/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static deleteApiAccess(api) {
    return http
      .delete(`/access_api/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static checkSmtpConnection({ host, port, secure, user, pass }) {
    return http
      .post(`/check-smtp-connection`, { host, port, secure, user, pass })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
}